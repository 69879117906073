import { defineStore } from 'pinia';
import { ref } from 'vue';
import { ElMessage } from 'element-plus';
import API_BILL_EXPORT from '~/api/bill/export-list';
import { DataType, ExportListDataType } from '~/models/bill-export-list';
import { ExportListType } from './model';


export const BILL_EXPORT_LIST_STORE = defineStore('BILL_EXPORT_LIST_STORE', () => {
  const loading = ref(false);
  // 页面是否打开
  const pageOpen = ref(false);
  const exportList = ref<ExportListType>({
    data: []
  });

  // 获取导出列表
  const getExportList = async () => {
    loading.value = true;
    const res = await API_BILL_EXPORT.getBillExportList();
    exportList.value.data = res.rows;
    loading.value = false;
  };

  // 判断页面打开刷新列表
  const refreshListByPageOpen = () => {
    if (pageOpen.value) {
      getExportList();
    }
  };

  // 按数据判断是否刷新列表
  const refreshListByDiffData = (data: ExportListDataType) => {
    // 若页面打开
    if (pageOpen.value) {
      // 查找数据
      const find = exportList.value.data.find(item => item.id === data.id);
      // 若找到数据 且 生成状态不同 则刷新页面
      if (find && find.status !== data.status) {
        // 刷新数据
        getExportList();
      }
    }
  };

  // 申请导出
  const applyForExport = async (calculateId: string, billCycle: string, dataType: DataType) => {
    // 获取导出数据
    const res = await API_BILL_EXPORT.getExportDataById({ calculateId, dataType });
    // 若未查询到数据
    if (!res.data) {
      // 申请导出
      await API_BILL_EXPORT.applyForExport({
        dataType,
        billCycle,
        calculateId
      });
      ElMessage.success('导出数据生成中，请点击“导出列表”进行查看');
      // 判断刷新列表
      refreshListByPageOpen();
    } else {
      const { status } = res.data;
      if (status === 0) {
        ElMessage.success('导出数据生成中，请点击“导出列表”进行查看');
      } else {
        ElMessage.success('导出数据已生成，请点击“导出列表”进行下载');
      }
      // 根据数据刷新列表
      refreshListByDiffData(res.data);
    }
  };

  // 清空数据
  const clear = () => {
    loading.value = false;
    pageOpen.value = false;
    exportList.value.data = [];
  };

  return {
    loading,
    pageOpen,
    exportList,
    getExportList,
    refreshListByPageOpen,
    refreshListByDiffData,
    applyForExport,
    clear
  };
});
