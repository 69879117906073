import { defineStore } from 'pinia';
import { ref } from 'vue';
import { TagListItemType } from './model';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import router from '~/router';
import { CACHE_CLEAR_STRATEGY } from './cache-clear-strategy';

export const TAGS_STORE = defineStore('TAGS_STORE', () => {
  // 标签列表
  const tagList = ref<TagListItemType[]>([]);

  // 页面缓存列表
  const cacheList = ref<string[]>([]);

  // 获取路由加入位置
  const getAddRoutePosition = (previousPath?: string) => {
    if (previousPath) {
      const index = tagList.value.findIndex(item => item.path === previousPath);
      return index + 1;
    }
    return tagList.value.length;
  };

  // 新增标签
  const ADD_TAG = (route: RouteLocationNormalizedLoaded, previousPath?: string) => {
    const { path, name, meta, params } = route;
    // 查找是否存在相同标签
    const findSameTag = tagList.value.findIndex(tag => tag.path === path);
    // 若不存在相同标签
    if (findSameTag < 0) {
      const position = getAddRoutePosition(previousPath);
      // 获取组件名称
      const compoenntName = (name || '');
      // 获取标题
      const title = meta && meta.title ? meta.title as string : '';
      const tagData = {
        path,
        name: compoenntName as string,
        title,
        params,
        close: true,
        scroll: 0
      };
      // 新增标签
      tagList.value.splice(position, 0, tagData);
    }
    // 新增缓存
    ADD_CACHE(route);
  };

  // 删除标签
  const DEL_TAG = (tag: TagListItemType) => {
    const findTag = GET_TAG(tag.path);
    if (findTag) {
      const index = tagList.value.findIndex(tag => tag.path === findTag.path);
      tagList.value.splice(index, 1);
      // 删除缓存
      DEL_CACHE(tag.name);
      return true;
    }
    return false;
  };

  // 查找标签
  const GET_TAG = (path: string) => {
    const findTag = tagList.value.find(tag => tag.path === path);
    return findTag;
  };

  // 关闭标签
  const CLOSE_TAG = (route: RouteLocationNormalizedLoaded) => {
    const tag = GET_TAG(route.path);
    if (tag) {
      DEL_TAG(tag);
      const lastTag = tagList.value[tagList.value.length - 1];
      router.push(lastTag.path);
      // 进入策略模式 清空数据缓存
      if (CACHE_CLEAR_STRATEGY[tag.name]) {
        CACHE_CLEAR_STRATEGY[tag.name](tag);
      }
    }
  };

  // 初始化标签
  const INIT_TAG = () => {
    if (tagList.value.length <= 0) {
      tagList.value.push({
        path: '/home',
        name: 'home',
        title: '首页',
        close: false,
        params: {},
        scroll: 0
      });
    }
  };

  // 新增页面缓存
  const ADD_CACHE = (route: RouteLocationNormalizedLoaded) => {
    const { name, meta } = route;
    // 若存在name 且 meta中的keepAlive为true 且 缓存列表中不包含name
    if (name && meta && meta.keepAlive && !cacheList.value.includes(name as string)) {
      // 新增缓存
      cacheList.value.push(name as string);
    }
  };

  // 删除缓存
  const DEL_CACHE = (name: string) => {
    // 查找标签列表中是否还存在相同name的标签
    const findCacheTag = tagList.value.findIndex(tag => tag.name === name);
    // 若未找到
    if (findCacheTag < 0) {
      // 查找缓存
      const delIndex = cacheList.value.findIndex(cache => cache === name);
      if (delIndex >= 0) {
        cacheList.value.splice(delIndex, 1);
      }
    }
  };

  // 清空缓存
  const CLEAR = () => {
    // 清空标签
    tagList.value = [];
    // 清空页面缓存
    cacheList.value = [];
  };

  return {
    tagList,
    cacheList,
    ADD_TAG,
    DEL_TAG,
    GET_TAG,
    CLOSE_TAG,
    INIT_TAG,
    ADD_CACHE,
    DEL_CACHE,
    CLEAR
  };
});