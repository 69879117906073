import { API_REQUEST } from '~/api/config';
import {
  ApplyForExportParamsType,
  ExportListRespType,
  GetExportDataByIdReqType,
  GetExportDataByIdRespType
} from '~/models/bill-export-list';

const API_BILL_EXPORT = {
  // 获取导出列表
  getBillExportList () {
    return API_REQUEST.get<ExportListRespType>({
      url: 'rrbilldetailfile/list'
    });
  },
  // 申请导出
  applyForExport (data: ApplyForExportParamsType) {
    return API_REQUEST.post({
      url: 'rrbilldetailfile/submit',
      data
    });
  },
  // 查询导出数据
  getExportDataById (data: GetExportDataByIdReqType) {
    return API_REQUEST.post<GetExportDataByIdRespType>({
      url: 'rrbilldetailfile/getByCalculateId',
      data
    });
  }
};

export default API_BILL_EXPORT;
