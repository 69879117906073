// 字符串转对象
JSON.tsParse = <T = any>(str: string): T => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return {} as T;
  }
};

// 判断字符串是否为JSON
export const isJSON = (str: string) => {
  try {
    const obj = JSON.parse(str);
    return Boolean(obj && typeof obj === 'object');
  } catch {
    return false;
  }
};

// 将对象转换为FormData
type TransFormDataParamsType = Record<string, string | File | Blob>;
export const transFormData = (data: TransFormDataParamsType) => {
  const keys = Object.keys(data);
  const formData = new FormData();
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const value = data[key];
    if (value) {
      formData.append(key, value);
    }
  }
  return formData;
};

// 日期转换
export const getDateFormat = (date: Date, format: string) => {
  let result = format;
  const formatDic: Record<string, number> = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  const yearReg = new RegExp('(y+)', 'i');
  if (yearReg.test(format)) {
    const yearExec = yearReg.exec(format);
    if (yearExec && yearExec[0]) {
      const year = result.replace(yearExec[0], `${date.getFullYear()}`.substring(4 - yearExec[0].length));
      result = year;
    }
  }
  const keys = Object.keys(formatDic);
  for (let i = 0; i < keys.length; i += 1) {
    const reg = new RegExp(`(${keys[i]})`);
    if (reg.test(format)) {
      const exec = reg.exec(format);
      if (exec && exec[0]) {
        const value = `${formatDic[keys[i]]}`;
        const replace = result.replace(exec[0], exec[0].length <= 1 ? value : `00${value}`.substring(value.length));
        result = replace;
      }
    }
  }
  return result;
};

// 下载文件
export const downloadFile = (url: string, done?: () => void) => {
  const iframe = document.createElement('iframe');
  iframe.src = url;
  iframe.style.display = 'none';
  document.body.appendChild(iframe);
  setTimeout(() => {
    document.body.removeChild(iframe);
    if (done) {
      done();
    }
  }, 500);
};

// 下载文件
export const downloadFileByA = (url: string, name: string) => {
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.dispatchEvent(new MouseEvent('click'));
};

// 下载图片
export const downloadImage = (src: string, name = '') => {
  const image = new Image();
  // 获取后缀
  const type = src.substring(src.lastIndexOf('.') + 1);
  // 解决跨域 Canvas 污染问题
  image.setAttribute('crossOrigin', 'anonymous');
  // 图片加载完成
  image.onload = () => {
    // 获取图片宽高
    const { width, height } = image;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    if (context) {
      context.drawImage(image, 0, 0, width, height);
      const url = canvas.toDataURL(`image/${type}`);
      const a = document.createElement('a');
      a.download = name || 'image';
      a.href = url;
      a.dispatchEvent(new MouseEvent('click'));
    }
  };
  image.src = src;
};

// url获取流
export const getBlobByUrl = async (url: string) => {
  const blob = await fetch(url).then(res => res.blob());
  return blob;
};

// blob转file
export const blobToFile = (blob: Blob, fileName: string) => {
  const file = new File([blob], fileName, { type: blob.type });
  return file;
};

// 下载流文件
export const downloadBlobFile = (fileName: string, data: Blob) => {
  const windowURL = window.URL || window.webkitURL;
  const blob = new Blob([data], { type: data.type });
  const url = windowURL.createObjectURL(blob);
  const a = document.createElement('a');
  a.download = fileName || 'file';
  a.href = url;
  a.dispatchEvent(new MouseEvent('click'));
  windowURL.revokeObjectURL(url);
};