import { RouteType } from '~/models/route';

export const pagesRoutes: RouteType[] = [
  {
    path: '/home',
    name: 'home',
    meta: { title: '首页', keepAlive: false },
    component: () => import('~/views/pages/home/index.vue')
  },
  // 基本信息
  {
    path: '/base-info',
    redirect: '/base-info/index'
  },
  {
    path: '/base-info/index',
    name: 'base-info-index',
    meta: { title: '基本信息', keepAlive: true },
    component: () => import('~/views/pages/base-info/index.vue'),
  },
  {
    path: '/base-info/create',
    name: 'create-base-info',
    meta: {
      title: '实名认证',
      keepAlive: true,
      activeMenu: '/base-info/index'
    },
    component: () => import('~/views/pages/base-info/create.vue')
  },
  // 合同签约
  {
    path: '/contract',
    name: 'contract',
    meta: { title: '合同签约', keepAlive: true },
    component: () => import('~/views/pages/contract/index.vue'),
  },
  // 对账单
  {
    path: '/bill',
    redirect: '/bill/list'
  },
  {
    path: '/bill/list',
    name: 'bill-list',
    meta: { title: '对账单', keepAlive: true },
    component: () => import('~/views/pages/bill/list/index.vue')
  },
  {
    path: '/bill/detail/:id',
    name: 'bill-detail',
    meta: {
      title: '对账单明细',
      keepAlive: true,
      activeMenu: '/bill/list'
    },
    component: () => import('~/views/pages/bill/detail/index.vue')
  },
  {
    path: '/bill/export',
    name: 'bill-export-list',
    meta: {
      title: '对账单导出列表',
      keepAlive: true,
      activeMenu: '/bill/list'
    },
    component: () => import('~/views/pages/bill/export-list/index.vue')
  },
  // 结算单
  {
    path: '/balance',
    redirect: '/balance/list'
  },
  {
    path: '/balance/list',
    name: 'balance-list',
    meta: { title: '结算单', keepAlive: true },
    component: () => import('~/views/pages/balance/list/index.vue')
  },
  {
    path: '/balance/detail/:id',
    name: 'balance-detail',
    meta: {
      title: '结算单明细',
      keepAlive: true,
      activeMenu: '/balance/list'
    },
    component: () => import('~/views/pages/balance/detail/index.vue')
  },
  // 银行信息
  {
    path: '/bank-info',
    name: 'bank-info',
    meta: { title: '银行信息', keepAlive: true },
    component: () => import('~/views/pages/bank-info/index.vue')
  },
  // 消息中心
  {
    path: '/notice',
    redirect: '/notice/list'
  },
  {
    path: '/notice/list',
    name: 'notice-list',
    meta: { title: '消息中心', keepAlive: true },
    component: () => import('~/views/pages/notice/list/index.vue')
  },
  {
    path: '/notice/detail/:id',
    name: 'notice-detail',
    meta: { title: '消息详情', keepAlive: true },
    component: () => import('~/views/pages/notice/detail/index.vue')
  },
  // 框架协议
  {
    path: '/framework',
    name: 'framework-agreeemnt',
    meta: { title: '框架协议', keepAlive: true },
    component: () => import('~/views/pages/framework-agreement/index.vue'),
  },
  // 反佣协议
  {
    path: '/rebate',
    name: 'rebate-agreeemnt',
    meta: { title: '返佣协议', keepAlive: true },
    component: () => import('~/views/pages/rebate-agreement/index.vue'),
  },
];